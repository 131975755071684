<div class="contents-wrapper">
  <section class="main-container">
    <img *ngIf="isMainPicketInit" class="protest-img is-back" @mainBg01
      [defaultImage]="'/assets/images/main/bg-01@0.25x.png'" [lazyLoad]="'/assets/images/main/bg-01@1x.png'" alt="">
    <img *ngIf="isMainPicketInit" class="protest-img is-middle" @mainBg02
      [defaultImage]="'/assets/images/main/bg-02@0.25x.png'" [lazyLoad]="'/assets/images/main/bg-02@1x.png'" alt="">
    <img *ngIf="isMainPicketInit" class="protest-img is-front" @mainBg03
      [defaultImage]="'/assets/images/main/bg-03@0.25x.png'" [lazyLoad]="'/assets/images/main/bg-03@1x.png'" alt="">
    <h2>피켓 함께 들어 기후위기 알리기 캠페인</h2>
  </section>
  <a class="btn-start" routerLink="/stepper" routerLinkActive="active">시작하기</a>

</div>