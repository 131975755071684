import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private analytics: AngularFireAnalytics) { }
  ngOnInit(): void {
    this.analytics.setCurrentScreen('home'); // sets `screen_name` parameter
    this.analytics.logEvent('screen_view', { name: 'appinit'}); // log event with `screen_name` parameter attached
  }
}
