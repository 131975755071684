export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBP-DNhn9epf8TUqAWXxi4oM6PSXoWyBbQ',
    authDomain: 'siheung-ecocenter.firebaseapp.com',
    databaseURL: 'https://siheung-ecocenter.firebaseio.com',
    projectId: 'siheung-ecocenter',
    storageBucket: 'siheung-ecocenter.appspot.com',
    messagingSenderId: '258868550397',
    appId: '1:258868550397:web:035527de754c283fe6bc94',
    measurementId: 'G-9Z535CBWFL'
  }
};
