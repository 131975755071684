import { Component, OnInit } from '@angular/core';
import { mainPicketAnimation, mainAniArr } from '../utils/animations';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [ mainAniArr ]
})
export class MainComponent implements OnInit {

  isMainPicketInit = false;
  constructor(private analytics: AngularFireAnalytics) {
    this.analytics.setCurrentScreen('main'); // sets `screen_name` parameter
    this.analytics.logEvent('constructor', { name: 'mainComponent'}); // log event with `screen_name` parameter attached
  }

  ngOnInit(): void {
    this.isMainPicketInit = true;
  }

}
