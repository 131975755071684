
export interface StepInfo {
    name: string;
    en: string;
    btnCopy: string | null;
}

export interface Keyword {
    name: string;
    en: string;

    isSelected?: boolean | null;
}


export interface SloganType {
    isSelected: boolean;
    en: string;
    alt: string | null;

}
export interface Slogan {
    isVertical?: boolean;
    name: string;
    en: string;
    alt: string; // have to set
    url?: string;

    keywords: Array<Keyword> | null;
    childItems: Array<SloganType> | null;
}




export interface Selected {
    slogan: Slogan | null;
    sloganType: SloganType | null;
}

export const SELECTED: Selected = {
    slogan: null,
    sloganType: null
};

export const KEYWORDS: Array<Keyword> = [
    // { name: '전체', en: 'all', isSelected: true },
    { name: '날씨', en: 'weather', isSelected: false },
    { name: '멸종', en: 'extinction',  isSelected: false  },
    { name: '미래세대',  en: 'future-generation',  isSelected: false  },
    { name: '소비', en: 'consumption',  isSelected: false  },
    { name: '식량', en: 'food',  isSelected: false  },
    { name: '에너지', en: 'energy',  isSelected: false  },
    { name: '전염병', en: 'epidemic',  isSelected: false  },
    { name: '채식', en: 'vegan',  isSelected: false  },
    { name: '터전', en: 'base',  isSelected: false  }
];

export const SLOGANS: Array<Slogan> = [

    {
        isVertical: false,
        name: '분명 공룡들도 미래가 있다고 생각했을걸',
        en: 'dinosaurs-future',
        url: '/assets/images/slogans/extinction/dinosaurs-future',
        alt: '',
        keywords: [ { name: '멸종', en: 'extinction' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
        ]
    },
    {
        isVertical: false,
        name: '당신은 늙어죽겠지만, 나는 기후위기로 죽을것 입니다.',
        en: 'i-will-die',
        url: '/assets/images/slogans/future-generation/i-will-die',
        alt: '',
        keywords: [ { name: '미래세대',  en: 'future-generation' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
            { isSelected: false, en: 'frame-04', alt: null, },
        ]
    },
    {
        isVertical: true,
        name: '멸종저항! 우리에게 남은 시간 7년!',
        en: 'resistance',
        url: '/assets/images/slogans/extinction/resistance',
        alt: '',
        keywords: [ { name: '멸종', en: 'extinction' } ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
        ]
    },
    {
        isVertical: false,
        name: '이 비의 이름은 장마가 아니라 기후위기입니다.',
        en: 'rain-name',
        url: '/assets/images/slogans/weather/rain-name',
        alt: '',
        keywords: [ { name: '날씨', en: 'weather' } ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
            { isSelected: false, en: 'frame-04', alt: null, }
        ]
    },

    {
        isVertical: false,
        name: '돌려주세요. 봄 다운 봄, 가을 다운 가을.',
        en: 'return-season',
        url: '/assets/images/slogans/weather/return-season',
        alt: '',
        keywords: [ { name: '날씨', en: 'weather' } ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, }
        ]
    },
    {
        isVertical: false,
        name: '더워 THE WAR',
        en: 'the-war',
        url: '/assets/images/slogans/weather/the-war',
        alt: '',
        keywords: [ { name: '날씨', en: 'weather' } ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, }
        ]
    },

    {
        isVertical: true,
        name: '다음 멸종은 누구?',
        en: 'who-is-next',
        url: '/assets/images/slogans/extinction/who-is-next',
        alt: '',
        keywords: [ { name: '멸종', en: 'extinction' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
        ]
    },
    
    {
        isVertical: false,
        name: '생태계 피라미드 멸종위기 프로듀스 101!',
        en: 'produce101',
        url: '/assets/images/slogans/extinction/produce101',
        alt: '',
        keywords: [ { name: '멸종', en: 'extinction' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
        ]
    },
    {
        isVertical: false,
        name: '기후위기 지금 말하고 당장 행동하라!',
        en: 'from-now-on',
        url: '/assets/images/slogans/extinction/from-now-on',
        alt: '',
        keywords: [
            { name: '멸종', en: 'extinction' },
        ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
        ]
    },

    {
        isVertical: false,
        name: '6살 인생에 벌써...기후위기!',
        en: '6-year-old',
        url: '/assets/images/slogans/future-generation/6-year-old',
        alt: '',
        keywords: [ { name: '미래세대',  en: 'future-generation' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, }
        ]
    },
    {
        isVertical: false,
        name: '당신들에게는 미래가 있었다. 우리에게도 미래가 있어야 한다!',
        en: 'future-too',
        url: '/assets/images/slogans/future-generation/future-too',
        alt: '',
        keywords: [ { name: '미래세대',  en: 'future-generation' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
        ]
    },
    {
        isVertical: false,
        name: '덜 소비하고 더 존재하라!',
        en: 'less',
        url: '/assets/images/slogans/consumption/less',
        alt: '',
        keywords: [ { name: '소비', en: 'consumption' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, }
        ]
    },
    {
        isVertical: false,
        name: '쓰레기를 사지 않을 권리, 알맹이만!',
        en: 'not-to-buy-trash',
        url: '/assets/images/slogans/consumption/not-to-buy-trash',
        alt: '',
        keywords: [ { name: '소비', en: 'consumption' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, }
        ]
    },

    {
        isVertical: false,
        name: '기후위기가 밥상도 바꾼다.',
        en: 'on-the-table',
        url: '/assets/images/slogans/food/on-the-table',
        alt: '',
        keywords: [ { name: '식량', en: 'food' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, }
        ]
    },

    {
        isVertical: false,
        name: '오징어! 니가 왜 서해에서 나와~ 니가 왜 거기서 나와~',
        en: 'squid',
        url: '/assets/images/slogans/food/squid',
        alt: '',
        keywords: [ { name: '식량', en: 'food' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
        ]
    },
    {
        isVertical: true,
        name: '기후위기 곧 식량위기',
        en: 'crisis',
        url: '/assets/images/slogans/food/crisis',
        alt: '',
        keywords: [ { name: '식량', en: 'food' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
        ]
    },


    {
        isVertical: true,
        name: '탈탈탈 탈석탄',
        en: 'desorbing-coal',
        url: '/assets/images/slogans/energy/desorbing-coal',
        alt: '',
        keywords: [ { name: '에너지', en: 'energy' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, }
        ]
    },
    {
        isVertical: true,
        name: '2030 탈석탄 2050 온실가스 배출제로',
        en: '2030-2050-goal',
        url: '/assets/images/slogans/energy/2030-2050-goal',
        alt: '',
        keywords: [ { name: '에너지', en: 'energy' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, }
        ]
    },


    {
        isVertical: false,
        name: '코로나는 기후위기가 낳은 팬데믹',
        en: 'covid19',
        url: '/assets/images/slogans/epidemic/covid19',
        alt: '',
        keywords: [ { name: '전염병', en: 'epidemic' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
            { isSelected: false, en: 'frame-04', alt: null, }
        ]
    },

    {
        isVertical: false,
        name: '기후위기가 전염병 확산을 부른다.',
        en: 'calling-spread',
        url: '/assets/images/slogans/epidemic/calling-spread',
        alt: '',
        keywords: [ { name: '전염병', en: 'epidemic' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, }
        ]
    },

    {
        isVertical: false,
        name: '기후위기와의 싸움이 전염병과의 싸움',
        en: 'fighting',
        url: '/assets/images/slogans/epidemic/fighting',
        alt: '',
        keywords: [ { name: '전염병', en: 'epidemic' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
        ]
    },


    {
        isVertical: false,
        name: '채소한끼 최소한끼',
        en: 'at-least-one-meal',
        url: '/assets/images/slogans/vegan/at-least-one-meal',
        alt: '',
        keywords: [ { name: '채식', en: 'vegan' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
        ]
    },
    {
        isVertical: false,
        name: 'GO VEGAN 지구를 살리는 채식',
        en: 'save-the-planet',
        url: '/assets/images/slogans/vegan/save-the-planet',
        alt: '',
        keywords: [ { name: '채식', en: 'vegan' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'picket-03', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
        ]
    },
    {
        isVertical: false,
        name: '고통 없는 식탁',
        en: 'table-without-pain',
        url: '/assets/images/slogans/vegan/table-without-pain',
        alt: '',
        keywords: [ { name: '채식', en: 'vegan' }, ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, },
        ]
    },


    {
        isVertical: false,
        name: '코로나 그만오게 도와주라 주라주라주라~ 지구 휴식좀 주라~아으아으아~',
        en: 'give-me-a-break',
        url: '/assets/images/slogans/base/give-me-a-break',
        alt: '',
        keywords: [ { name: '터전', en: 'base', } ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, }
        ]
    },
    {
        isVertical: false,
        name: '지구야 그만변해 내가 변할게',
        en: 'earth-stop-changing',
        url: '/assets/images/slogans/base/earth-stop-changing',
        alt: '',
        keywords: [ { name: '터전', en: 'base', } ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, }
        ]
    },
    {
        isVertical: false,
        name: 'There is No PLANet B',
        en: 'there-is-no-planet-b',
        url: '/assets/images/slogans/base/there-is-no-planet-b',
        alt: '',
        keywords: [ { name: '터전', en: 'base', } ],
        childItems: [
            { isSelected: false, en: 'picket-01', alt: null, },
            { isSelected: false, en: 'picket-02', alt: null, },
            { isSelected: false, en: 'frame-01', alt: null, },
            { isSelected: false, en: 'frame-02', alt: null, },
            { isSelected: false, en: 'frame-03', alt: null, }
        ]
    }
];



export const STEPINFOS: Array<StepInfo>  = [
    { name: '', en: 'intro-01', btnCopy: '네, 동참할게요!' },
    { name: '어떤 피켓을 만들어볼까요?', en: 'selectSlogan', btnCopy: '이 피켓으로 할게요' },
    { name: '~ 에 관심이 많으시군요! 마음에 드는 피켓 형태를 골라보세요', en: 'selectSloganType', btnCopy: '이걸로 해볼게요!' },
    { name: '(사용자이미지 편집)', en: 'importUserImg', btnCopy: '이대로 완성하기' },
    { name: '로딩 페이지', en: 'loading', btnCopy: null },
    { name: '결과 페이지', en: 'result', btnCopy: null },
];

