import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { StepperComponent } from './stepper/stepper.component';


const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  // open
  { path: 'main', component: MainComponent },
  { path: 'stepper', component: StepperComponent },
  { path: '**', component: MainComponent },
  // close
  // { path: 'main', component: CloseComponent },
  // { path: '**', component: CloseComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
