import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/analytics";

import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { MatSnackBar } from "@angular/material/snack-bar";

import { animationArr } from "../utils/animations";

import {
  StepInfo,
  STEPINFOS,
  SLOGANS,
  Selected,
  SELECTED,
  Slogan,
  SloganType,
  Keyword,
  KEYWORDS,
} from "../core/model";
import * as _ from "lodash";
import html2canvas from "html2canvas";

// import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
  animations: [animationArr],
})
export class StepperComponent implements OnInit {
  @ViewChild("slogansRef") slogansRef: ElementRef;
  @ViewChild("cropBox") cropBox: ElementRef;
  @ViewChild("downloadLink") downloadLink: ElementRef;

  isHashtagCopied = false;
  isImgToolInit = false; // 이미지 불러오기, 회전하기 init 상태

  tags = `
  #기후위기오다 #기후위기비상행동 
  #시흥에코센터 #우리는살고싶다
  기후위기 피켓만들기 참여는 climate-comes.kr`;

  loadImgState; // 사용자 이미지 로드 상태
  loadImgURL; // 사용자가 촬영 또는 가져온 이미지
  croppedImgURL; // 사용자가 로드후 편집 한 이미지

  pageType: string;
  isDevMode = false; // 개발 모드 여부
  // isDevMode = true; // 개발 모드 여부
  // 현재 스텝 인덱스
  stepIndex = 0;
  // stepIndex = 5; // result view

  // 현재 스텝 정보
  currentStepInfo: StepInfo;
  // 전체 스텝 정보 배열
  stepInfos: Array<StepInfo> = STEPINFOS;

  // Progress Bar Data
  progressPer = 0;

  // 키워드(카테고리) 목록
  keywords: Array<Keyword> = KEYWORDS;
  selectable = true;
  removable = true;

  // 슬로건 목록
  slogans: Array<Slogan> = SLOGANS;
  filteredSlogans: Array<Slogan>;

  isFilterAll = true; // 필터링 전체 옵션

  // 사용자 선택 옵션
  selected: Selected = SELECTED;

  // 선택한 슬로건 타입
  selectedSloganType: string;

  // 이미지 압축
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  // 회전값
  degree = 0;
  // private imageCompress: NgxImageCompressService
  constructor(
    public snackBar: MatSnackBar,
    private analytics: AngularFireAnalytics
  ) {
    this.pageType = "step";
    // 개발모드
    if (this.isDevMode) {
      this.pageType = "result";
      this.croppedImgURL = "/assets/images/test/cropped-img@2x.png";
      // console.log("isDevMode!", this.croppedImgURL);
    }
    this.currentStepInfo = this.stepInfos[this.stepIndex];
  }
  ngOnInit(): void {
    this.analytics.setCurrentScreen("stepper"); // sets `screen_name` parameter
    this.analytics.logEvent("screen_view", { name: "stepinit" }); // log event with `screen_name` parameter attached
    this.filteredSlogans = this.slogans;
  }

  setRotate() {
    // console.log('getRotate');
    const rotateOffset = 90;
    this.degree = this.degree === 270 ? 0 : this.degree + rotateOffset;
    this.analytics.logEvent("set_img_rotate", { name: "사용자이미지회전" });
  }

  /// ngx ver ////
  detectFiles(event) {
    // console.log('detectFiles!');
    this.analytics.logEvent("click_load_userimg", {
      name: "사용자이미지불러오기",
    });
    // this.loadImgURL = null;
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.getOrientation(file, (orientation) => {
        this.getBase64(file, orientation);
      });
    }
  }

  getOrientation(file, callback) {
    let reader: any;
    let target: EventTarget;

    reader = new FileReader();
    reader.onload = (event) => {
      const view = new DataView(event.target.result);

      if (view.getUint16(0, false) !== 0xffd8) {
        return callback(-2);
      }

      const length = view.byteLength;
      let offset = 2;

      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return callback(-1);
          }
          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return callback(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker && 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  }
  getBase64(file, orientation) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadstart = () => {
      // console.log("reader onload start");
      this.loadImgState = "loading";
    };
    reader.onloadend = () => {
      // console.log("reader onload end");
      this.loadImgState = "view";
    };
    // reader.onprogress = () => { console.log('reader progress'); };

    reader.onload = () => {
      const base64 = reader.result;
      this.resetOrientation(base64, orientation, (resetBase64Image) => {
        // console.log(resetBase64Image);
        // console.log("get URL!");
        this.loadImgURL = resetBase64Image;
        if (!this.isImgToolInit) { this.isImgToolInit = true; }
      });
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  }
  resetOrientation(srcBase64, srcOrientation, callback) {
    const img = new Image();

    // console.log("resetOrientation, srcOrientataion", srcOrientation);

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      //
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };

    img.src = srcBase64;
  }

  imgResize(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let img = document.createElement("img");

      const canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      let reader: any;

      reader = new FileReader();
      reader.onload = (e) => (img.src = e.target.result);
      reader.readAsDataURL(file);

      ctx.drawImage(img, 0, 0);

      const MAX_WIDTH = 800;
      const MAX_HEIGHT = 600;
      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;

      ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      var dataurl = canvas.toDataURL("image/png");
    }
  }

  // 슬로건 필터링
  onFilterItem(keyword: Keyword | null, index?: number) {
    // console.log('onFilterItem', keyword);
    // 필터링 아이템 클릭시 선택 값 초기화
    this.selected.slogan = null;

    // 키워드 클릭하는 경우
    if (keyword !== null) {
      this.slogansRef.nativeElement.scrollTo(0, 0);
      // 해당 키워드 상태 변경
      this.keywords[index].isSelected = !this.keywords[index].isSelected;

      // 전체 키워드 상태 값 조회
      const activeOptionArr = _.filter(this.keywords, { isSelected: true });
      // console.log(activeOptionArr);
      // 모든 키워드가 비활성화 된 경우
      if (activeOptionArr.length === 0) {
        // console.log('모든 키워드 비활성화');
        // 전체 태그 활성화 & 모든 데이터 적용
        this.isFilterAll = true;
        // this.filteredSlogans = this.slogans;
        this.filteredSlogans = _.shuffle(this.slogans);
      } else {
        // console.log('선택된 키워드 수 : ', activeOptionArr.length);
        // 활성 키워드 1개 이상인 경우
        // 1) 전체 태그 비활성화
        this.isFilterAll = false;
        // 2) 활성 키워드 기준 필터링 적용
        this.applyFilter();
      }
    } else {
      // 전체 키워드 클릭하는 경우

      // 1) 이미 전체 태그 활성화 상태
      if (this.isFilterAll) {
        // console.log('이미 전체 키워드 선택된 상태');
      } else {
        this.slogansRef.nativeElement.scrollTo(0, 0);
        // 2) 전체 태그 비활성화 상태
        // 전체 태그 활성화 && 모든 데이터 적용
        this.isFilterAll = true;
        // this.filteredSlogans = this.slogans;
        this.filteredSlogans = _.shuffle(this.slogans);

        // 모든 키워드 태그 비활성화
        _.forEach(this.keywords, (keyword) => {
          keyword.isSelected = false;
        });
      }
    }
  }

  applyFilter() {
    // console.log('applyFilter');
    let resultArr = []; // 필터링 할 때마다 초기화. 빈 배열에서 시작.
    // Collection을 객체로
    const keywordsOBJ = _.keyBy(this.keywords, "en");

    // 2) 전체 슬로건 항목을 미리 키워드 별로 필터링
    const weatherSlogans = _.filter(this.slogans, (slogan) =>
      _.find(slogan.keywords, { en: "weather" })
    );
    const extinctionSlogans = _.filter(this.slogans, (slogan) =>
      _.find(slogan.keywords, { en: "extinction" })
    );
    const futureGenerationSlogans = _.filter(this.slogans, (slogan) =>
      _.find(slogan.keywords, { en: "future-generation" })
    );
    const consumptionSlogans = _.filter(this.slogans, (slogan) =>
      _.find(slogan.keywords, { en: "consumption" })
    );
    const foodSlogans = _.filter(this.slogans, (slogan) =>
      _.find(slogan.keywords, { en: "food" })
    );
    const energySlogans = _.filter(this.slogans, (slogan) =>
      _.find(slogan.keywords, { en: "energy" })
    );
    const epidemicSlogans = _.filter(this.slogans, (slogan) =>
      _.find(slogan.keywords, { en: "epidemic" })
    );
    const veganSlogans = _.filter(this.slogans, (slogan) =>
      _.find(slogan.keywords, { en: "vegan" })
    );
    const baseSlogans = _.filter(this.slogans, (slogan) =>
      _.find(slogan.keywords, { en: "base" })
    );

    // 3) 현재 선택된 옵션인 경우 결과 배열에 concat.
    if (keywordsOBJ["weather"].isSelected) {
      resultArr = _.concat(resultArr, weatherSlogans);
    }
    if (keywordsOBJ["extinction"].isSelected) {
      resultArr = _.concat(resultArr, extinctionSlogans);
    }
    if (keywordsOBJ["future-generation"].isSelected) {
      resultArr = _.concat(resultArr, futureGenerationSlogans);
    }
    if (keywordsOBJ["consumption"].isSelected) {
      resultArr = _.concat(resultArr, consumptionSlogans);
    }
    if (keywordsOBJ["food"].isSelected) {
      resultArr = _.concat(resultArr, foodSlogans);
    }
    if (keywordsOBJ["energy"].isSelected) {
      resultArr = _.concat(resultArr, energySlogans);
    }
    if (keywordsOBJ["epidemic"].isSelected) {
      resultArr = _.concat(resultArr, epidemicSlogans);
    }
    if (keywordsOBJ["vegan"].isSelected) {
      resultArr = _.concat(resultArr, veganSlogans);
    }
    if (keywordsOBJ["base"].isSelected) {
      resultArr = _.concat(resultArr, baseSlogans);
    }

    // 중복 소거가 되어있지만, 해둠
    this.filteredSlogans = _.shuffle(resultArr);
  }

  setOption(sectionName: string, selectedValue: Slogan | SloganType) {
    this.selected[sectionName] =
      this.selected[sectionName] === selectedValue ? null : selectedValue;
    this.analytics.logEvent("select_option", {
      sectionName,
      value: selectedValue.en,
    });
  }

  copyInfo(infoType: string) {
    if (infoType === "hashtag") {
      this.snackBar.open("해시태그 복사 완료! 😆", "", { duration: 1000 });
      this.analytics.logEvent("copy_hashtag", { name: "hashtagCopy" });
      this.isHashtagCopied = !this.isHashtagCopied;
    } else {
      // this.snackBar.open('접근성 텍스트 복사 완료😆', '', { duration: 1000 });
    }
  }
  openUrl(target: string) {
    // const url =
    //   "https://www.instagram.com/explore/tags/%EA%B8%B0%ED%9B%84%EC%9C%84%EA%B8%B0%EC%98%A4%EB%8B%A4/";
    this.analytics.logEvent("open_url_instagram", { name: "openInstagram" });
    // window.open(url);
    // window.open(targetUrls.instagramHome);
  }

  // 단계 이동 (뒤로, 다음)
  setStepIndex(dir: string) {
    // 뒤로 버튼
    if (dir === "prev") {
      if (this.currentStepInfo.en === "selectSlogan") {
        this.selected.slogan = null;
      }
      if (this.currentStepInfo.en === "selectSloganType") {
        this.selected.slogan = null;
        this.selected.sloganType = null;
      }
      // 세부타입 선택 후 다시 뒤로 돌아가기 누를경우
      if (this.currentStepInfo.en === "importUserImg") {
        this.selected.sloganType = null;
        this.croppedImgURL = null;
      }

      // add
      this.stepIndex--;
      this.currentStepInfo = this.stepInfos[this.stepIndex];
    } else {
      // 다음 버튼

      // 크롭 완료 단계에서 다음으로 넘어갈 때, 크롭먼저 하고, 스텝 바꾸기
      if (this.currentStepInfo.en === "importUserImg") {
        this.downloadStart("crop");
        this.pageType = "loading";
      } else {
        // 크롭할땐 건드리지 말기
        window.scrollTo(0, 0);
      }
      this.stepIndex++;
      this.currentStepInfo = this.stepInfos[this.stepIndex];
    }

    // console.log(`stepIndex:${this.stepIndex}, stepInfo: ${JSON.stringify(this.currentStepInfo)}`);
    // 프로그래스바 업데이트
    this.progressPer = (this.stepIndex / (this.stepInfos.length - 1)) * 100;
  }

  getButtonState() {
    if (this.currentStepInfo.en === "importUserImg" && !this.loadImgURL) {
      return true;
    }
    if (this.currentStepInfo.en === "selectSlogan" && !this.selected.slogan) {
      return true;
    }
    if (
      this.currentStepInfo.en === "selectSloganType" &&
      !this.selected.sloganType
    ) {
      return true;
    }
    return false;
  }

  getImgUrl(sectionName: string, dimension: string) {
    // console.log('getImgUrl!');
    const basePath = "/assets/images/slogans";
    const sloganPath =
      this.selected.slogan.keywords[0].en + "/" + this.selected.slogan.en;
    const endPath = `@${dimension}.png`;

    if (sectionName === "sloganType") {
      const sloganTypePath = this.selected.sloganType.en;
      console.log(`${basePath}/${sloganPath}/${sloganTypePath}${endPath}`);
      return `${basePath}/${sloganPath}-${sloganTypePath}${endPath}`;
    }
    // console.log(`${basePath}/${sloganPath}${endPath}`);
    return `${basePath}/${sloganPath}${endPath}`;
  }

  // 이미지 불러와서 미리보기 영역에 표시
  readImgUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      // console.log("event.target.files && event.target.files[0]");
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.loadImgURL = reader.result);
      reader.readAsDataURL(file);
    } else {
      // console.log("event.target.files && event.target.files[0] is not working");
    }
  }

  // 편집한 이미지 설정
  downloadStart(target: string) {
    // console.log("downloadStart", target);
    window.scrollTo(0, 0);
    // crop or result
    if (target === "crop") {
      this.downloadLink.nativeElement.download = "cropped-user-img.png";
      // this.downloadLink.nativeElement.click();
      html2canvas(this.cropBox.nativeElement, {
        useCORS: true,
        allowTaint: true,
      })
        .then((canvas) => {
          const imgUrl = canvas.toDataURL("image/png");
          this.croppedImgURL = imgUrl;

          setTimeout(() => {
            // console.log("result!");
            this.stepIndex++;
            this.pageType = "result";
            this.currentStepInfo = this.stepInfos[this.stepIndex];
            this.analytics.logEvent("goal_completion", { name: "최종페이지" });
            this.analytics.logEvent("selected_option", {
              slogan: this.selected.slogan.en,
              sloganType: this.selected.sloganType.en,
            });
          }, 3000);
          // console.log('this.croppedImgURL', this.croppedImgURL);
        })
        .catch((err) => console.error(err));
    } else {
      console.error("downloadStart is not working with", target);
    }
  }

  logEvent(eventName: string) {
    if (eventName === "instagram") {
      this.analytics.logEvent("open_url_instagram", { name: "openInstagram" });
    }
  }
  trackByFn(index, item) {
    return item.name;
  }
}
