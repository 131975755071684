import { state, style, animate, transition, query, stagger, trigger, group } from '@angular/animations';

export const optionList = trigger('optionList', [
  transition('* => *', [ // each time the binding value changes
    query(':enter', [
      style({ 
        opacity: 0,
      }),
      stagger(80, [
        animate('.5s .5s ease-in-out', style({ 
          opacity: 1,
        }))
      ])
    ], { optional: true })
  ])
]);

// Option List Trigger (enter only)
export const listAnimation = trigger('listAnimation', [
    transition('* => *', [ // each time the binding value changes
      query(':enter', [
        style({ opacity: 0 }),
        stagger(100, [
          animate('.6s .8s', style({ opacity: 1 }))
        ])
      ], { optional: true })
    ])
]);
// slogansTrigger
export const slogansTrigger = trigger('slogansTrigger', [
  transition('* => *', [ // each time the binding value changes
    query(':enter', [
      style({ opacity: 0 }),
      stagger(150, [
        animate('1s .8s', style({ opacity: 1 }))
      ])
    ], { optional: true })
  ])
]);
// SloganType ChildItems
export const sloganTypesTrigger = trigger('sloganTypesTrigger', [
  transition('* => *', [ // each time the binding value changes
    query(':enter', [
      style({ opacity: 0 }),
      stagger(150, [
        animate('.4s', style({ opacity: 1 }))
      ])
    ], { optional: true })
  ])
]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s ease-in-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('.5s ease-in-out', style({ opacity: 0 }))
  ])
]);


// NgIf Trigger (enter only)
export const ngIfTrigger = trigger('ngIfTrigger', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('.5s', style({ opacity: 1 })),
    ])
]);


export interface Prop {
  delay: number | null;
  dur: number | null;
}
export interface AniProp {
  enter: Prop;
  leave?: Prop;
}
export interface AniProps {
  title: AniProp;
  view: AniProp;
}

export const aniPropsLoading: AniProps = {
  title: {
      enter: { delay: 1, dur: .5, },
      leave: { delay: 0, dur: .4 }
  },
  view: {
      enter: { delay: 1.2, dur: 2 },
      leave: { delay: 0, dur: .2 }
  }
};

// loading -> result
export const aniPropsResult = {
  // 결과페이지 delay = loadingView.leave.delay + dur + offset
  title: {
      enter: { delay: `${aniPropsLoading.view.leave.delay + aniPropsLoading.view.leave.dur + 1}`, dur: .5, },
      // leave: { delay: .5, dur: .5 }
  },
  view: {
      enter: { delay: `${aniPropsLoading.view.leave.delay + aniPropsLoading.view.leave.dur + 1.2}`, dur: .5, },
      // leave: { delay: .5, dur: .5 }
  },
};


// 로딩페이지 타이틀
export const loadingViewTitle = trigger('loadingViewTitle', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(`${aniPropsLoading.title.enter.dur}s ${aniPropsLoading.title.enter.delay}s`, style({ opacity: 1 })),
  ]),
  transition(':leave', [
      style({ opacity: 1 }),
      animate(`${aniPropsLoading.title.leave.dur}s ${aniPropsLoading.title.leave.delay}s`, style({ opacity: 0 })),
    ])
]);
// 로딩페이지 이미지 뷰
export const loadingView = trigger('loadingView', [
  transition(':enter', [
      style({ opacity: 0 }),
      animate(`${aniPropsLoading.view.enter.dur}s ${aniPropsLoading.view.enter.delay}s`, style({ opacity: 1 })),
  ]),
  transition(':leave', [
        style({ opacity: 1 }),
        animate(`${aniPropsLoading.view.leave.dur}s ${aniPropsLoading.view.leave.delay}s`, style({ opacity: 0 })),
  ])
]);


//  결과 페이지 제목
export const resultViewTitle = trigger('resultViewTitle', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(`${aniPropsResult.title.enter.dur}s`, style({ opacity: 1 })),
  ])
]);

// 결과 페이지 이미지 뷰
export const resultView = trigger('resultView', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(`${aniPropsResult.view.enter.delay}s ${aniPropsResult.view.enter.dur}s`, style({ opacity: 1 })),
  ])
]);



// 메인페이지
// slogansTrigger
export const mainPicketAnimation = trigger('mainPicketAnimation', [
  transition('* => *', [ // each time the binding value changes
    query(':enter', [
      style({ opacity: 0 }),
      stagger(300, [
        animate('2s .8s', style({ opacity: 1 }))
      ])
    ], { optional: true })
  ])
]);




export const mainBg01 = trigger('mainBg01', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s 1s', style({ opacity: 1 })),
  ])
]);

export const mainBg02 = trigger('mainBg02', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s 2s', style({ opacity: 1 })),
  ])
]);
export const mainBg03 = trigger('mainBg03', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s 3s', style({ opacity: 1 })),
  ])
]);

export const mainBtn = trigger('mainBtn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s 4s', style({ opacity: 1 })),
  ])
]);

export const mainAniArr = [
  mainBg01,
  mainBg02,
  mainBg03,
  mainBtn
];




export const animationArr = [
  slogansTrigger,
  fadeInOut,
  optionList,
  sloganTypesTrigger,
  listAnimation,
  ngIfTrigger,
  resultView,
  resultViewTitle,
  loadingViewTitle,
  loadingView,
];
