<div class="contents-wrapper">

  <!-- Progress bar -->
  <section *ngIf="pageType === 'step'  && stepIndex !== 0" class="progressbar-container" aria-hidden="true">
    <mat-progress-bar mode="determinate" [value]="progressPer"></mat-progress-bar>
  </section>





  <div class="btn-container" *ngIf="pageType === 'step'">
    <!-- button prev -->
    <button *ngIf="stepIndex !== 0 && pageType === 'step'" class="btn-prev" (click)="setStepIndex('prev')"
      type="button">
      <img [defaultImage]="'/assets/images/ui/icons/arrow_back@0.5x.png'"
        [lazyLoad]="'/assets/images/ui/icons/arrow_back@2x.png'" alt="">
      <span class="is-visually-hidden">이전</span>
    </button>
    <!-- btn next -->
    <button class="btn-next" [class.is-intro]="stepIndex === 0" [disabled]="getButtonState()"
      (click)="setStepIndex('next')">
      {{ currentStepInfo.btnCopy }}
    </button>
  </div>


  <!-- [attr.aria-pressed]="getStepState()" -->

  <section class="stepper-container">
    <section class="step-header">
      <ng-container *ngIf="currentStepInfo.en === 'selectSlogan'">
        <h2 class="step-title">피켓은 준비해놨답니다!<br />맘에 드는 문구를 골라주세요.</h2>
      </ng-container>
      <ng-container *ngIf="currentStepInfo.en === 'selectSloganType'">
        <h2 class="step-title">재밌는 피켓을 고르셨네요!<br />맘에 드는 배치도 골라주세요. </h2>
      </ng-container>
      <ng-container *ngIf="currentStepInfo.en === 'importUserImg'">
        <ng-container *ngIf="!loadImgURL">
          <h2 class="step-title">배경 사진을 넣어봐요!</h2>
          <p class="subtitle">사진을 불러온 뒤 크기를 조정할 수 있어요.</p>
        </ng-container>
        <ng-container *ngIf="loadImgURL">
          <h2 class="step-title">멋진 조합인데요?</h2>
          <p class="subtitle">피켓에 맞춰 사진을 조정해 보세요.</p>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="currentStepInfo.en === 'loading' ">
        <h2 class="step-title" @loadingViewTitle>피켓을 합성중이예요. <br />잠시만 기다려주세요~</h2>
      </ng-container>
    </section>

    <section class="step-body" [class.is-margin-top-0]="stepIndex === 0">

      <!-- intro -->
      <section class="step" *ngIf="stepIndex == 0" @ngIfTrigger>
        <div class="step-intro-container">
          <div class="graphic is-left">
            <img [defaultImage]="'/assets/images/intro-action/graphic-left@0.5x.png'"
              [lazyLoad]="'/assets/images/intro-action/graphic-left@1x.png'" alt="">
          </div>
          <div class="graphic is-top">
            <img [defaultImage]="'/assets/images/intro-action/graphic-top@0.5x.png'"
              [lazyLoad]="'/assets/images/intro-action/graphic-top@1x.png'" alt="">
          </div>
          <div class="graphic is-right-bottom">
            <img [defaultImage]="'/assets/images/intro-action/right-bottom@0.25x.png'"
              [lazyLoad]="'/assets/images/intro-action/right-bottom@1x.png'" alt="">
          </div>
          <div class="inner-container">
            <div class="bottom-graphics">
              <img [defaultImage]="'/assets/images/intro-action/graphic-bottom@0.5x.png'"
                [lazyLoad]="'/assets/images/intro-action/graphic-bottom@1x.png'" alt="">
            </div>
          </div>
          <div class="step-intro-msg-container">
            <p class="step-intro-msg">
              기후위기,<br />
              우리가 인지하지 못하는 사이<br />
              일상 곳곳에 스며 있습니다.
            </p>
            <p class="step-intro-msg">
              기후위기를 인간의 힘으로<br />
              막을 수 있는 시간은 이제 겨우<br />
              7년밖에 남지 않았습니다.
            </p>
            <p class="step-intro-msg">
              기후위기의 심각성을<br />
              알리기 위한 피켓 시위에<br />
              동참해주세요!
            </p>
          </div>

        </div>
      </section>

      <!-- (슬로건 선택) 어떤 피켓을 만들어 볼까요?  -->
      <section class="step" *ngIf="currentStepInfo.en === 'selectSlogan'">
        <!-- 슬로건 필터링 칩 -->
        <section class="slogan-filter-container">
          <mat-chip-list class="filter-items" aria-label="키워드 필터링 버튼 목록">
            <mat-chip class="filter-item" [class.is-selected]="isFilterAll === true" disableRipple
              (click)="onFilterItem(null)">
              <span class="filter-name">전체</span>
            </mat-chip>

            <mat-chip class="filter-item" *ngFor="let keyword of keywords; let i = index;" [removable]="removable"
              [class.is-selected]="keyword.isSelected" disableRipple (removed)="onFilterItem(keyword, i)"
              (click)="onFilterItem(keyword, i)">
              <span class="filter-name">{{keyword.name}}</span>

              <mat-icon matChipRemove *ngIf="keyword.isSelected">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </section>

        <!-- 슬로건 목록 -->
        <div class="slogans" #slogansRef>
          <ng-container *ngIf="filteredSlogans">

            <button class="slogan" *ngFor="let slogan of filteredSlogans; trackBy: trackByFn" @optionList
              [class.is-active]="selected.slogan === slogan" [class.is-horizontal]="!slogan.isVertical"
              [class.is-vertical]="slogan.isVertical" [attr.aria-pressed]="selected.slogan === slogan"
              (click)="setOption('slogan', slogan)">

              <!-- [ngStyle]="{ 'min-width': getImgSize(content, 'width'), 'min-height': getImgSize(content, 'height'), 'height': getImgSize(content, 'height') }" -->
              <img [defaultImage]="slogan.url +  '-slogan@0.25x.png' " [lazyLoad]="slogan.url + '-slogan@0.5x.png'"
                [alt]="slogan.name">

            </button>
          </ng-container>

        </div>
      </section>

      <!-- (슬로건 타입 선택) 내용 필요 -->
      <section class="step" *ngIf="currentStepInfo.en === 'selectSloganType'">
        <div class="slogan-type-container">
          <div class="slogan-types" [@sloganTypesTrigger]="selected.slogan.childItems.length">
            <button *ngFor="let sloganType of selected.slogan.childItems" class="slogan-type"
              [class.is-active]="selected.sloganType === sloganType" (click)="setOption('sloganType', sloganType)"
              type="button">
              <img
                [defaultImage]="'/assets/images/slogans/'+selected.slogan.keywords[0].en + '/'+ selected.slogan.en + '-' + sloganType.en + '@0.25x.png'"
                [lazyLoad]="'/assets/images/slogans/'+selected.slogan.keywords[0].en + '/'+ selected.slogan.en +  '-' + sloganType.en + '@0.5x.png'"
                [alt]="sloganType.name">
              <!-- <span class="is-visually-hidden"></span> -->
            </button>
          </div>
        </div>
      </section>
      <section class="step" *ngIf="currentStepInfo.en === 'importUserImg'">

        <div class="edit-container">
          <div id="crop-box" class="preview-container" #cropBox @ngIfTrigger>
            <!-- 업로드 클릭 버튼 영역-->
            <div *ngIf="!loadImgURL" class="upload-guide-container">
              <button type="button" class="btn-upload" (click)="fileInput.click()">
                <figure class="icon-container" aria-hidden="true">
                  <img class="icon" 
                    [defaultImage]="'/assets/images/ui/icons/image_search@0.5x.png'"
                    [lazyLoad]="'/assets/images/ui/icons/image_search@2x.png'" alt="">
                </figure>
                <span class="btn-text">사진 가져오기</span>
              </button>
            </div>
          
            <!-- html2canvas는 타겟요소의 첫 번째 요소를 캡쳐하기때문에 만든 콘테이너. -->
            <div class="capture-container">
              <!-- 선택한 피켓 이미지 -->
              <img class="img-selected-picket" [src]="getImgUrl('sloganType', '0.5x')">
              
              <!-- 사용자 업로드 이미지 -->
              <div class="crop-box">
                <pinch-zoom *ngIf="loadImgURL"
                  [overflow]="'visible'" 
                  [limit-zoom]="10" 
                  [limitPan]="true">

                  <img class="crop-box-userimg" 
                    [ngStyle]="{'transform':'rotate('+degree+'deg)'}" 
                    [src]="loadImgURL"
                    alt="your image">
                </pinch-zoom>
              </div>
            </div>
          </div> <!-- crop box -->

          <div class="img-tools" *ngIf="isImgToolInit" @ngIfTrigger>
            <button class="btn-reupload" 
              (click)="fileInput.click()"
              [disabled]="!loadImgURL"
              type="button">
              <figure class="icon-container" aria-hidden="true">
                <img class="icon" 
                  [defaultImage]="'/assets/images/ui/icons/image_search@0.5x.png'"
                  [lazyLoad]="'/assets/images/ui/icons/image_search@2x.png'" alt="">
              </figure>
              <span class="btn-text">사진 불러오기</span>
            </button>
            <button class="btn-reupload" 
              (click)="setRotate()" 
              [disabled]="!loadImgURL"
              type="button">
              <figure class="icon-container" aria-hidden="true">
                <img class="icon" [defaultImage]="'/assets/images/ui/icons/rotate_90_degrees_cw@0.5x.png'"
                  [lazyLoad]="'/assets/images/ui/icons/rotate_90_degrees_cw@2x.png'" alt="">
              </figure>
              <span class="btn-text">사진 회전하기</span>
            </button>
          </div>
        </div>


      </section>

      <!-- <input id="file" #fileInput  type="file"  accept="image/*" hidden  (change)="fileInputChange($event)" > -->
      <input id="file" #fileInput type="file" accept="image/*" hidden (change)="detectFiles($event)">
      <!-- <input id="file" #fileInput  type="file"  accept="image/*" hidden  (change)="readImgUrl($event)" > -->


      <!-- 로딩 페이지 -->
      <section class="step" *ngIf="currentStepInfo.en === 'loading'" @loadingView>
        <div class="loading-img-container">
          <img [defaultImage]="'/assets/images/ui/loading-bg@0.5x.png'"
            [lazyLoad]="'/assets/images/ui/loading-bg@1x.png'" alt="이글 이글 뜨거워지는 지구 이미지">
        </div>

      </section>

      <!-- 결과 페이지  -->
      <section class="step is-result-view" *ngIf="currentStepInfo.en === 'result'" @resultView>
        <div class="result-step-header" @resultViewTitle>
          <h2 class="step-title is-result">쨘쨔쟌! 정말 멋진데요?</h2>
          <p class="subtitle">
            혼자보기 너무 아깝네요! SNS에 공유해서<br />
            다른 분들에게도 기후위기에 대해 알려주세요!
          </p>
        </div>
        <div *ngIf="croppedImgURL" class="cropped-img-container">
          <img class="cropped-img" [src]="croppedImgURL" alt="">
        </div>
        <div class="event-info-container">
          <p class="event-guide">
            사진을 꾹 눌러 저장한 뒤,<br />
            아래 해시태그와 함께 SNS에 올려요!
          </p>
          <!-- 해시태그 카피 영역 -->
          <div class="event-hashtag">
            <div class="copytext-container">
              <p class="copytext">
                <span>#기후위기오다</span>
                <span>#기후위기비상행동</span>
                <span>#시흥에코센터</span>
                <span>#우리는살고싶다</span>
                <!-- <span>기후위기 피켓 만들기</span>
                <span>climate-comes.kr</span> -->
              </p>
            </div>

            <button class="btn-copy" mat-button disableRipple [cdkCopyToClipboard]="tags" type="button"
              (click)="copyInfo('hashtag')">
              <ng-container *ngIf="isHashtagCopied">복사 완료</ng-container>
              <ng-container *ngIf="!isHashtagCopied">해시태그 복사하기</ng-container>
            </button>
          </div>

          <!-- <p class="event-tag">#기후위기오다 #기후위기비상행동 #시흥에코센터 #우리는살고싶다</p>
          <button class="btn-copy-tags" 
            [cdkCopyToClipboard]="tags" (click)="copyInfo('hashtag')">해시태그 복사하기</button> -->

          <a class="link-open-url" target="_blank"
            href="https://www.instagram.com/explore/tags/%EA%B8%B0%ED%9B%84%EC%9C%84%EA%B8%B0%EC%98%A4%EB%8B%A4/"
            (click)="logEvent('instagram')">
            <span class="link-text">인스타그램에 업로드하러 가기</span>
          </a>

        </div>
        <!-- .event-info-container -->
        <section class="credit-container">
          <div class="credit">
            <h3 class="credit-role">기획 및 운영</h3>
            <div class="credit-name is-ecocenter">시흥에코센터 초록배곧</div>
            <div class="credit-name-detail">위지연, 조설화</div>
            <div class="credit-detail">
              나와 우리의 변화를 통해 삶의 터전인<br />
              지구가 건강해지는 데 기여합니다.
            </div>
            <a class="credit-cta" href="https://sh-ecocenter.or.kr" target="_blank" rel="noopener noreferrer">
              <span class="is-visually-hidden">시흥에코센터</span>
              <span class="link-text">웹사이트 구경가기</span>
            </a>
          </div>
          <div class="credit">
            <h3 class="credit-role">제작</h3>
            <div class="credit-name">스투키 스튜디오</div>
            <div class="credit-detail">
              웹사이트, 데이터 시각화 작업을 합니다.<br />
              쓰임새 있는 결과물을 만들기 위해 고민합니다.
            </div>
            <a class="credit-cta" href="https://stuckyi.studio" target="_blank" rel="noopener noreferrer">
              <span class="is-visually-hidden">스투키 스튜디오</span>
              <span class="link-text">웹사이트 구경가기</span>
            </a>
          </div>
          <div class="credit is-contributor">
            <p class="credit-contributor">
              이 캠페인의 피켓 이미지는<br />
              '기후위기비상행동'에서 사용된<br />
              피켓을 기반으로 제작되었습니다.
            </p>
          </div>
        </section>
      </section>



    </section>



  </section>
  <a #downloadLink></a>
</div>