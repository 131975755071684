import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Angular Materail Modules
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';

// Etc Modules
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { LazyLoadImageModule } from 'ng-lazyload-image';
// import { ExportAsModule } from 'ngx-export-as';

// Components
import { StepperComponent } from './stepper.component';
import { BrowserModule } from '@angular/platform-browser';




export const STEPPER_MATERIAL_MODULES  = [
  MatButtonModule,
  MatProgressBarModule,
  ClipboardModule,
  MatSnackBarModule,
  DragDropModule,
  MatChipsModule,
  MatIconModule,
];

export const STEPPER_MODULES = [
  // ImageCropperModule,
  // ExportAsModule,
  LazyLoadImageModule,
  PinchZoomModule
];


@NgModule({
  declarations: [StepperComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    STEPPER_MODULES,
    STEPPER_MATERIAL_MODULES
  ],
})
export class StepperModule { }
